<template>
    <div v-if="activity.title" style="font-size: .3rem;color:rgb(58,58,58)">
        <van-swipe @change="onChange" height="240" class="my-swipe" indicator-color="white">
            <van-swipe-item @click="preview(index)"  v-for="(item,index) in activity.photo">
                <img style="width:100%" v-lazy="item.url"/>
            </van-swipe-item>
            <template #indicator>
                <div class="custom-indicator">{{ current + 1 }}/{{activity.photo.length}}</div>
            </template>
        </van-swipe>
        <div style="padding:.1rem .2rem;width:calc(100% - .4rem)">
            <h4>
                {{activity.title||'未找到活动'}}
            </h4>
            <div class="u-color">
                {{activity.introduce||'9月19'}}
            </div>
            <div class="exim">
                <h4>活动发起人：</h4>
                <img class="user-img" style="" :src="activity.user.faceUrl">
                <div class="ellp">{{activity.user.nickname}}</div>
            </div>
            <div class="exim">
                <img class="img" src="../../assets/pic/time.png">
                <div class="u-color">时间：</div>
                <div class="ellp">{{activity.start_datetime}}</div>
            </div>
            <div class="exim">
                <img class="img" src="../../assets/pic/address.png">
                <div class="u-color">地点：</div>
                <div class="ellp">{{activity.location}}</div>
            </div>
            <div class="exim">
                <img class="img" src="../../assets/pic/consume.png">
                <div class="u-color">消费：</div>
                <div class="ellp">{{activity.consume}}</div>
            </div>
            <div class="exim">
                <img class="img" src="../../assets/pic/people_count.png">
                <div class="u-color">人数：</div>
                <div class="ellp">{{activity.max_member}}</div>
            </div>
            <div class="exim">
                <div style="font-weight: bolder">已报名：</div>
                <div class="ellp" style="flex: unset" v-for="member in activity.members">
                    <img class="user-img" :src="member.faceUrl" />
                </div>
            </div>

<!--            <div v-if="activity.isApply===STATUS.apply">-->
<!--                <view style="margin-bottom: .4rem">申请中</view>-->
<!--                <van-button @click="cancelApply" style="margin-top: .2rem" color="#F0DB49" type="primary" block>取消申请-->
<!--                </van-button>-->
<!--            </div>-->
<!--            <div v-else>-->
                <van-button @click="ensureApply" style="margin-top: .4rem" color="#F0DB49" type="primary" block>申请
                </van-button>
<!--            </div>-->
        </div>

        <div>
            <wx-open-launch-app
                    id="launch-btn"
                    appid="wxd0d77f2e7e898602"
                    extinfo="your-extinfo"
            >
                <script type="text/wxtag-template">
                    <style>.btn {
                        padding: 12px
                    }</style>
                    <button class="btn">App内查看</button>
                </script>
            </wx-open-launch-app>
        </div>
    </div>
    <div v-else>
        <van-empty image="error" description="未找到活动" />

    </div>

</template>
<!--
关于聊天页面，只实现展示功能，不实现聊天功能。
-->
<script>
    // import TIM from 'tim-js-sdk';
    // import chat from "./chat";
    //
    import wx from 'weixin-js-sdk';
    import Vue from 'vue';
    import {Lazyload} from 'vant';
    import Toast from 'vant/lib/toast'
    import { ImagePreview } from 'vant';

    Vue.use(Lazyload);

    //申请状态（1：申请，2：通过，3：拒绝，4：取消）
    const STATUS = {
        'default': 0,
        'apply': 1,
        'adopt': 2,
        'refuse': 3,
        'cancel': 4,
    };
    export default {
        name: "activityDetail",
        data() {
            return {
                type: true,

                activity: {
                    isApply: 1,
                    user:{
                        nickname:'暂无人员'
                    },
                    photo:[
                        {
                            url:'https://img01.yzcdn.cn/vant/custom-empty-image.png'
                        }
                    ]
                },

                qid: '',
                current: 0,
                tim: null,

                img_preview:[],//预览图片数组

                STATUS: {
                    'default': 0,
                    'apply': 1,
                    'adopt': 2,
                    'refuse': 3,
                    'cancel': 4,
                }
            }
        },
        created() {
            // this.initChatRoom()
        },
        components:{
            [ImagePreview.Component.name]: ImagePreview.Component,
        },
        mounted() {
            this.getActivity();
            var btn = document.getElementById('launch-btn');
            btn&&btn.addEventListener('launch', function (e) {
                console.log('success');
            });
            btn&&btn.addEventListener('error', function (e) {
                console.log('fail', e.detail);
            });
        },
        methods: {
            preview(index){
                ImagePreview({
                    images:this.img_preview,
                    startPosition: index,
                });
            },
            initChatRoom() {
                let options = {
                    SDKAppID: 1400492591 // 接入时需要将0替换为您的即时通信 IM 应用的 SDKAppID
                };
                // 创建 SDK 实例，`TIM.create()`方法对于同一个 `SDKAppID` 只会返回同一份实例
                this.tim = TIM.create(options); // SDK 实例通常用 tim 表示
                // 登录后才有res.data.content.id,res.data.content.userSig
                chat(this.tim)
            },
            onChange(index) {
                this.current = index;
            },
            ensureApply() {
                window.location="https://www.yuechengapp.com/";
                // this.$axios.post('https://api.yuechengapp.com/activityApply', {
                //     activity_id: this.activity.id
                // }).then((res) => {
                //     if (res.data.code === '0000') {
                //         Toast('申请活动成功');
                //         this.activity.isApply = this.STATUS.cancel
                //     }
                // })
            },
            cancelApply() {
                this.$axios.put(`https://api.yuechengapp.com/activityApply/${this.activity.activity_apply_id}`, {
                    status: this.STATUS.cancel
                }).then((res) => {
                    if (res.data.code === '0000') {
                        Toast('取消申请成功');
                        this.activity.isApply = this.STATUS.cancel
                    }
                })
            },
            getActivity() {
                if(!this.$route.query.id){
                    return
                }
                let id=this.$route.query.id;
                let hash=this.$route.hash;
                let qid=id+hash;
                this.qid=qid;

                this.$axios.post(`https://api.yuechengapp.com/activity/qid`, {
                    // qid: "@TGS#24GYP4NHN"
                    qid
                }).then((res) => {
                    if (res.data.code === '0000'&&res.data.content.activity) {
                        this.activity=res.data.content.activity;
                        this.img_preview =this.activity.photo.map((item)=>{
                            return item.url
                        });
                    }
                })
            }
        }
    }
</script>

<style scoped lang="less">
    .card {
        padding: .1rem;
        background: white;
    }

    .exim {
        display: flex;
        align-items: center;
        .distance;
    }

    .distance {
        margin-top: .2rem;
    }

    .ellp {
        flex: 1;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }

    .img {
        width: .3rem;
        margin-right: .1rem;
    }

    .u-color {
        color: grey
    }


    /*.my-swipe .van-swipe-item {*/
    /*    color: #fff;*/
    /*    font-size: 20px;*/
    /*    text-align: center;*/
    /*}*/

    .user-img{
        border-radius: 50%;
        width:.5rem;
        height:.5rem;
        margin-right: .1rem;
    }

    .custom-indicator {
        position: absolute;
        color:white;
        right: 5px;
        bottom: 5px;
        padding: 2px 5px;
        z-index:999;
        font-size: 12px;
        background: rgba(0, 0, 0, 0.1);
    }
</style>
